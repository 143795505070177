$color_1: #fff;
$color_2: #000;
$color_3: #ff6833;
$background-color_1: transparent;
$background-color_2: #282828;
$background-color_3: #f1f1f1;
$background-color_4: #ff6833;
$background-color_5: #fff;
$background-color_6: #4caf50;


@-webkit-keyframes slide-down {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes slide-down {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@-webkit-keyframes pulsec {
	0% {
		transform: scale(.95);
		box-shadow: 0 0 0 0 rgba(236,28,36); 
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
	}
	to {
		transform: scale(.95);
		box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
	}
}
@keyframes pulsec {
	0% {
		transform: scale(.95);
		box-shadow: 0 0 0 0 rgba(236,28,36);
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
	}
	to {
		transform: scale(.95);
		box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
	}
}

// whatsc
@-webkit-keyframes pulse {
	0% {
		transform: scale(.95);
		box-shadow: 0 0 0 0 rgba(76, 175, 80, .7);
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
	}
	to {
		transform: scale(.95);
		box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
	}
}
@keyframes pulse {
	0% {
		transform: scale(.95);
		box-shadow: 0 0 0 0 rgba(76, 175, 80, .7);
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
	}
	to {
		transform: scale(.95);
		box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
	}
}



/* header  img meio*/

header .imgheader {
	background-color: #f1f1f1;
	padding:10px;
}
header {
	position: fixed;
	z-index: 1009;
	top: 0;
	left: 0;
	width: 100%;
	transition: 400ms;
	text-align: -webkit-center;
	nav.navbar {
		background-color: #282828 !important;
		.ajuste-brand {
			background-color: $background-color_1;
			padding: 5px;
		}
		background-color: $background-color_3;
		box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
		width: 100%;
		.navbar-brand {
			img {
				transition: 400ms;
				width: 260px;
				max-height: 4em;
				&:active {
					transform: scale(.9);
				}
			}
		}
		#navbarNavDropdown {
			ul.navbar-nav {
				li.nav-item {
					a.nav-link {
						text-transform: uppercase;
						position: relative;
						font-size: 1rem;
						color: #fff !important;
						font-weight: 500;
						transition: 400ms;
						padding: 0;
						&:hover {
							padding: 0;
							border-radius: 6px;
							color: $color_1 !important;
						}
						&:after {
							position: absolute;
							bottom: 0;
							left: 0%;
							width: 0;
							height: 2px;
							content: "";
							transition: width .5s ease-in;
							
						}
					}
					margin: 4px 10px;
				}
				li.navbar-link {
					a.nav-link {
						&:hover {
							&:after {
								width: 100%;
							}
						}
					}
				}
				li.navbar-link.dropdown {
					&:hover {
						>.dropdown-menu {
							display: block;
							-webkit-animation: slide-down 1s ease;
							animation: slide-down 1s ease;
							transform: scale(1);
							transition: 300ms;
						}
					}
					.dropdown-menu {
						z-index: -1;
						margin: 0;
						border: 0;
						border-radius: 0 0 4px 4px;
					}
					a {
						&:hover {
							background-color: $background-color_4 !important;
							color: $color_2;
							transform: scale(1.1);
						}
						&:active {
							background-color: $background-color_4 !important;
							color: $color_2;
						}
						&:focus {
							background-color: $background-color_4 !important;
						}
					}
				}
			}
		}
	}
	#nav-top {
		background-color: #ff6833;
		ul {
			margin: 0;
			padding-left: 0;
			list-style: none;
			li {
				&:last-child {
					margin-right: 0;
				}
				
				padding: 4px;
				color: $color_1;
				transition: 300ms;
				a {
					font-size: 14px;
					padding: 4px;
					color: $color_1;
					transition: 300ms;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
	.navbar-nav {
		align-items: center;
	}
	nav {
		.navbar-collapse {
			align-self: center !important;
		}
	}
	.collapse {
		visibility: visible !important;
	}
}
.space_ajust {
	position: relative;
	padding: 2px 8px;
	width: 100%;
	height: 38px;
	text-align: center;
	margin-right: 0;
	margin-bottom: 0px;
	border-radius: 0;
	z-index: 2001;
	li {
		img {
			width: auto;
			height: auto;
			transform: scale(1);
			transition: 400ms;
			&:hover {
				transform: scale(1.1);
				transition: 400ms;
			}
		}
	}
}
.dropdown {
	&:hover {
		>.dropdown-menu {
			display: block;
		}
	}
}
.dropdown-item.active {
	background-color: $background-color_4 !important;
}
.dropdown-item {
	&:active {
		background-color: $background-color_4 !important;
	}
}
.dropdown-menu {
	background-color: $background-color_4;
	padding: 1px 0px !important;
	transform: scale(1);
	transition: 300ms;
	font-weight: 0.9em !important;
	margin-top: -1px !important;
	a {
		color: $color_2 !important;
		position: relative;
		background-color: $background-color_4;
		font-weight: 600;
		font-size: 0.9em !important;
		transition: 300ms;
		&:hover {
			color: $color_3;
			background-color: $background-color_5;
			font-size: 0.95em !important;
		}
	}
}
header.header-fixed {
	position: fixed;
	top: -40px;
	transition: 400ms;
	nav.navbar {
		align-items: center;
		.ajuste-brand {
			padding: 5px;
		}
		.navbar-brand {
			img {
				transition: 400ms;
				width: 225px;
				max-height: 3em;
			}
		}
	}
}
footer {
	background-color: #5e5e62;
	position: relative;
	z-index: 10;
	padding: 40px 0;
	h6 {
		color: $color_3;
		text-transform: uppercase;
		margin: 0 5px;
		font-size: 1.7rem;
	}
	a {
		display: block;
		color: $color_1;
		text-decoration: none;
		&:hover {
			color: $color_1;
		}
	}
	ul {
		li {
			a {
				color: $color_1;
				list-style: none;
			}
			color: $color_1;
			list-style: none;
		}
	}
	p {
		color: $color_1;
		a {
			&:hover {
				color: $color_1;
			}
		}
	}

	iframe{
		width: 100%;
		height: 100%;
	}
	#whatsapp {
		position: fixed;
		right: 16px;
		bottom: 16px;
		z-index: 100;
		a {
			transition: font-size padding-right .3s .3s;
			background-color: $background-color_6;
			color: $color_1;
			border-radius: 50%;
			text-decoration: none;
			padding: 16px;
			-webkit-animation: pulse 2s infinite;
			animation: pulse 2s infinite;
			&:hover {
				border-radius: 8px 50px 50px 8px;
				#whatsapp-text {
					font-size: .9em;
					padding-right: 16px;
				}
			}
			#whatsapp-text {
				transition: all .5s;
				font-size: 0;
				line-height: 1.2;
				.title {
					font-size: 1.1em;
					font-weight: 700;
				}
			}
			img {
				width: 50px;
			}
		}
	}
	
}
// @media only screen and (max-width:1441px) {
	
// }
// @media only screen and (max-width:1294px) {
	
// }
@media only screen and (max-width:1148px) {
	header {
		top: 0px;
		left: 0;
		nav.navbar {
			box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
			padding-bottom: 0;
			width: 100%;
			border-radius: 1px;
			.ajuste-brand {
				margin-top: 0;
				margin-right: 0;
				margin-left: 0;
				padding: 17px;
			}
		}
	}
	header.header-fixed {
		position: fixed;
		top: -35px;
		transition: 400ms;
	}
}
@media only screen and (max-width:992px) {
	header {
		nav.navbar {
			#navbarNavDropdown {
				ul.navbar-nav {
					li.nav-item {
						a.nav-link {
							font-size: .9em;
						}
					}
					li.navbar-link.dropdown {
						&:hover {
							>.dropdown-menu {
								-webkit-animation: ease;
								animation: ease;
							}
						}
					}
				}
			}
			button.navbar-toggler {
				margin-bottom: 3px;
			}
		}
	}
}
@media only screen and (max-width:769px) {
	header {
		nav.navbar {
			padding-bottom: .5em;
			align-items: center;
		}
		position: absolute;
		top: 0px;
	}
	.space_ajust {
		position: relative;
		padding: 2px 8px;
		width: 100%;
		height: 100%;
		text-align: center;
		margin-right: 0;
		margin-bottom: 0px;
		border-radius: 0;
		z-index: 2001;
	}
	header.header-fixed {
		position: fixed;
		top: -76px;
		transition: 400ms;
	}
}
@media only screen and (max-width:575px) {
	header {
		nav.navbar {
			.ajuste-brand {
				margin-top: 0;
				margin-right: 0;
				margin-left: 0;
				padding: 5px;
				margin-top: 0px;
				margin-right: 0px;
				margin-left: 0px;
			}
			.navbar-brand {
				margin-right: .2em;
				img {
					width: 170px;
					max-height: 3em;
				}
			}
			padding: 0 1rem !important;
		}
		#nav-top {
			ul {
				li {
					font-size: .85em;
				}
			}
		}
		top: -5px;
	}
	header.header-fixed {
		nav.navbar {
			.navbar-brand {
				img {
					transition: 400ms;
					width: 125px;
					max-height: 3em;
				}
			}
		}
		top: 0;
	}
	.space_ajust {
		position: relative;
		padding: 2px 8px;
		width: 100%;
		height: 100%;
		text-align: center;
		margin-right: 0;
		margin-bottom: 0px;
		border-radius: 0;
		z-index: 2001;
	}
}

.navbar-nav a:hover {
	color: #000;
  }
  .dropdown-item:hover, .dropdown-item:focus {
	color: #000;
	text-decoration: none;
	background-color: #fff !important;
	transition: 400ms;
  }
  
  .nav-link {
	display: inline-block !important;
	text-decoration: none;
  }
  
  .nav-link::after {
	content: '' !important;
	display: block !important;
	width: 0 !important;
	height: 3px !important;
	background: #ff6833 !important;
	transition: width .2s;
  }
  
  .nav-link:hover::after {
	width: 100% !important;
	transition: width .3s;
  }
  
@media only screen and (max-width: 325px) {
    header nav.navbar .navbar-brand img {
        width: 115px;
       
    }
}
@media (max-width: 575px) {
	header {
		nav.navbar {
			border-radius: unset !important;
		}
		.space_ajust{
			display: none;
		}
	}

	.header-fixed{
			top: 0;
	}


	footer {
		.img-fluid{
			width: 200px;
			margin:10px 0;
		}
		text-align: center;
		h6{
				margin: 5px;
				font-size: 1.6rem;

		}
		img{
			width: 100%;
			height: 100%;
		}
		#whatsapp {
			a {
				img {
					width: 30px;
				}
			}
		}
		.uls {
			text-align: center;
		}
		.img-meio {
			margin-top: 0px;
		}
		
	}

}